import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import {
  selectAvailableOptionsSendTo,
  updateAvailableOptionsSendTo,
} from "../common/patientSelectorModal/patientSelectorSlice";
import {
  updateInOfficeSendTo,
  updateSelectedDevice,
  selectInOfficePatientSendTo,
  selectIsTabInitialized,
  selectInOfficeSelectedDevice,
} from "../inOfficePatients/FormAssignerModal/inOfficeModalSlice";

import { useAppSelector } from "../../../../global/hooks/useTypedRedux.hook";
import { TDevice } from "../../../../global/types/settings/DeviceSettings/TDevice.type";
import { SecondaryText } from "../../../../global/components/SecondaryText/SecondaryText";
import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { selectIsAssignFormModalVisible } from "../inOfficePatients/inOfficePatientsSlice";
import {
  DEVICE_OPTION,
  getOptions,
  PATIENT_TEXT_OPTION,
  PATIENT_EMAIL_OPTION,
} from "./getSendToOptions";
import { SendToView } from "./SendToView";
import { SendToTooltip } from "./SendToTooltip";
import {
  InOfficeOptionsArgs,
  IInOfficePatientsSendTo,
} from "./SendTo.interfaces";
import { EFormAssignmentNotificationType } from "../../../../global/enums/EFormAssignmentNotificationType";
import { SCustomMenuWrapper, SCustomMenuItem } from "./styles";
import { SEND_TO } from "./constants";
import { useGetFormAssignmentModalDataQuery } from "../../../../global/services/document-manager/formAssignmentModalDataApi";

const SendTo = ({
  patientId,
  dispatch,
  setValue,
  watch,
  patientTextInformation,
  patientEmailInformation,
  onChange,
}: IInOfficePatientsSendTo) => {
  const IS_QR_DISABLED = false;
  const [isSendToAlreadySet, setIsSendToAlreadySet] = useState(false);
  const [currentSendTo, setCurrentSendTo] = useState<number | null>(null);
  const sendToValue: EFormAssignmentNotificationType = useAppSelector(
    selectInOfficePatientSendTo(patientId)
  );
  const isFormAssignerModalOpen: boolean = useAppSelector(
    selectIsAssignFormModalVisible
  );
  const storedAvailableOptions: EFormAssignmentNotificationType[] =
    useAppSelector(selectAvailableOptionsSendTo);
  const isTabInitialized: boolean = useAppSelector(
    selectIsTabInitialized(patientId)
  );

  const { data: patientData, isFetching } = useGetFormAssignmentModalDataQuery(
    { patientId },
    {
      skip: !patientId,
    }
  );

  const selectedDevice = useAppSelector(
    selectInOfficeSelectedDevice(patientId)
  );
  const hasDevices = Boolean(patientData?.enabledDevices?.length);
  // eslint-disable-next-line no-console
  console.log(
    "[YAP-6637] patientData: ",
    patientData,
    "[YAP-6637] sendtovalue",
    sendToValue
  );
  useEffect(() => {
    const setAvailableOptions = (
      isTextDisabled: boolean,
      isEmailDisabled: boolean
    ): void => {
      const sendToAvailableOptions: EFormAssignmentNotificationType[] = [];

      if (patientData?.enabledDevices?.length)
        sendToAvailableOptions.push(DEVICE_OPTION.value);
      if (!isTextDisabled)
        sendToAvailableOptions.push(PATIENT_TEXT_OPTION.value);
      if (!isEmailDisabled)
        sendToAvailableOptions.push(PATIENT_EMAIL_OPTION.value);

      if (
        sendToAvailableOptions.length &&
        sendToAvailableOptions.length !== storedAvailableOptions?.length
      ) {
        dispatch(updateAvailableOptionsSendTo(sendToAvailableOptions));
      }
    };

    setAvailableOptions(
      patientTextInformation.disabled,
      patientEmailInformation.disabled
    );
  }, [
    patientTextInformation.disabled,
    patientEmailInformation.disabled,
    storedAvailableOptions,
    patientData?.enabledDevices,
  ]);

  useEffect((): void => {
    if (patientData && !isTabInitialized) {
      dispatch(
        updateInOfficeSendTo({ patientId, sendTo: storedAvailableOptions?.[0] })
      );
    }
  }, [storedAvailableOptions]);

  useEffect(() => {
    if (isFetching) return;

    const shouldSelectFirstDevice = () => {
      return (
        hasDevices && !selectedDevice && !patientData?.data?.[0]?.formAssignment
      );
    };

    const selectFirstDevice = () => {
      dispatch(
        updateSelectedDevice({
          selectedDevice: null,
          patientId,
        })
      );
      setValue(SEND_TO, DEVICE_OPTION.value);
    };

    const selectSpecificDevice = (device) => {
      dispatch(
        updateSelectedDevice({
          selectedDevice: device,
          patientId,
        })
      );
      setValue(SEND_TO, DEVICE_OPTION.value);
    };

    const handlePreviousDevice = () => {
      if (hasDevices && !selectedDevice && patientData?.previousDevice) {
        dispatch(
          updateSelectedDevice({
            selectedDevice: patientData.previousDevice,
            patientId,
          })
        );
      }
    };

    const syncSendToWithSelectedDevice = () => {
      // eslint-disable-next-line no-console
      console.log(
        "[YAP-6637] in syncsendtowithselecteddevice",
        selectedDevice,
        watch(SEND_TO),
        DEVICE_OPTION.value
      );
      if (selectedDevice && watch(SEND_TO) !== DEVICE_OPTION.value) {
        // eslint-disable-next-line no-console
        console.log("in syncsendto if condition");
        setValue(SEND_TO, DEVICE_OPTION.value);
      }
    };

    // Main execution
    if (shouldSelectFirstDevice()) {
      // eslint-disable-next-line no-console
      console.log("[YAP-6637] shouldselectfirstdevice: ");
      selectFirstDevice();
    } else if (hasDevices && selectedDevice) {
      // eslint-disable-next-line no-console
      console.log(
        "[YAP-6637] hasdevice and selectedevice",
        hasDevices,
        selectedDevice
      );
      selectSpecificDevice(selectedDevice);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        "[YAP-6637] Before handlePreviousDevice",
        hasDevices,
        "selecctedDevice",
        selectedDevice
      );
      handlePreviousDevice();
    }

    syncSendToWithSelectedDevice();
  }, [
    hasDevices,
    selectedDevice,
    isFormAssignerModalOpen,
    patientData?.enabledDevices,
  ]);
  useEffect(() => {
    if (
      patientData?.data?.[0]?.formAssignment?.notifications &&
      !isSendToAlreadySet
    ) {
      const lastSendToType =
        patientData.data[0].formAssignment.notifications.at(-1);
      if (lastSendToType?.type in EFormAssignmentNotificationType) {
        const initialType =
          EFormAssignmentNotificationType[lastSendToType.type];
        // eslint-disable-next-line no-console
        console.log("[YAP-6637] initialtype", initialType); // Sync with form state
        const type = EFormAssignmentNotificationType[initialType];
        setCurrentSendTo(type);
        setIsSendToAlreadySet(true);
      }
    } else {
      setCurrentSendTo(sendToValue);
    }
  }, [patientData, isSendToAlreadySet, sendToValue]);
  const renderDevicesOrNotFoundMessage = useCallback((): JSX.Element => {
    if (!hasDevices) return <SecondaryText>No devices found.</SecondaryText>;

    const isDeviceOptionSelected: boolean = sendToValue === DEVICE_OPTION.value;

    const handleDeviceClick = (e: SelectChangeEvent): void => {
      const { value: id } = e.target;
      const deviceClicked: TDevice = patientData?.enabledDevices.find(
        (device: TDevice): boolean => device.id === id
      );
      dispatch(
        updateSelectedDevice({
          selectedDevice: deviceClicked,
          patientId: patientId,
        })
      );
    };

    return (
      <SCustomMenuWrapper
        disabled={!isDeviceOptionSelected}
        variant="standard"
        onChange={handleDeviceClick}
        value={selectedDevice?.id || "placeholder"}
        MenuProps={{ PaperProps: { style: { maxWidth: 500 } } }}
      >
        {!selectedDevice && (
          <SCustomMenuItem
            value="placeholder"
            disabled={true}
            title="Select linked device"
            key=""
            isSelected={false}
          >
            <span
              style={{
                color: "#E5E5E5",
              }}
            >
              Select linked Device
            </span>
          </SCustomMenuItem>
        )}
        {patientData?.enabledDevices.map((device: TDevice) => (
          <SCustomMenuItem
            isSelected={selectedDevice?.name === device.name}
            title={device.name}
            key={device.id}
            value={device.id}
          >
            {device.name}
          </SCustomMenuItem>
        ))}
      </SCustomMenuWrapper>
    );
  }, [selectedDevice, hasDevices, sendToValue]);

  const optionsConditions: InOfficeOptionsArgs = useMemo(() => {
    return {
      deviceCondition: { disabled: !hasDevices },
      qrCodeCondition: { disabled: IS_QR_DISABLED },
      textCondition: { disabled: patientTextInformation?.disabled },
      emailCondition: { disabled: patientEmailInformation?.disabled },
      tooltips: {
        patientText: (
          <SendToTooltip missedFields={patientTextInformation?.missedFields} />
        ),
        email: (
          <SendToTooltip missedFields={patientEmailInformation?.missedFields} />
        ),
      },
    };
  }, [hasDevices, patientTextInformation, patientEmailInformation]);
  // eslint-disable-next-line no-console
  console.log("[YAP-6637] just before render return", sendToValue);
  return (
    <SendToView
      options={getOptions(optionsConditions)}
      setValue={setValue}
      sendTo={currentSendTo}
      onChange={onChange}
      renderDevicesOrNotFoundMessage={renderDevicesOrNotFoundMessage}
    />
  );
};

export const InOfficePatientsSendTo = React.memo(SendTo, areEqualProps);
